import logo from './motionj.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p>
          I will wait till you sing.
        </p>
        <span
          className="App-link"
        >
          josoblue@gmail.com<br />
          (포커제이 게임 문의)
        </span>
      </header>
    </div>
  );
}

export default App;
